export const PlotHeaders = {
    'Altitude':{
        obj : 'altitude',
        title: 'Altitude (feet)',
        offset: 5000
    },
    'Speed':{
        obj : 'speed',
        title: 'Speed (kts)',
        offset: 50
    },
    'Track Distance':{
        obj : 'track_dist',
        title: 'Track Dist (feet)',
        offset: 0
    },
    'Thrust':{
        obj : 'thrust',
        title: 'Thrust',
        offset: 50
    },
    'Time':{
        obj : 'timestamps',
        title: 'Time from start (s)',
        offset: 50
    }
}