export const Waypoints = [
  {
    "code": "EDDYY",
    "name": "EDDYY",
    "lat": 37.374903,
    "lon": -122.119028,
    "alt": 6000,
    "version": 3
  },
  {
    "code": "MENLO",
    "name": "MENLO",
    "lat": 37.463694,
    "lon": -122.153667,
    "alt": 4000
  },
  {
    "code": "AMEBY",
    "name": "AMEBY",
    "lat": 37.371828,
    "lon": -122.0581,
    "alt": 4000
  },
  {
    "code": "ARCHI",
    "name": "ARCHI",
    "lat": 37.490806,
    "lon": -121.875556,
    "alt": 8000
  },
  {
    "code": "SIDBY",
    "name": "SIDBY",
    "lat": 37.450711,
    "lon": -122.144722,
    "alt": 4000
  },
  {
    "code": "EDDYY2",
    "name": "EDDYY2",
    "lat": 37.326444,
    "lon": -122.099722,
    "alt": 6000,
    "version": 2
  },
  {
    "code": "NARWL",
    "name": "NARWL",
    "lat": 37.274781,
    "lon": -122.079306,
    "alt": 8000
  },
  {
    "code": "DUMBA",
    "name": "DUMBA",
    "lat": 37.503517,
    "lon": -122.096147,
    "alt": 4500
  },
  {
    "code": "FAITH",
    "name": "FAITH",
    "lat": 37.401217,
    "lon": -121.8619,
    "alt": 8000
  },
  {
    "code": "TRDOW",
    "name": "TRDOW",
    "lat": 37.493828,
    "lon": -121.985542,
    "alt": 6000
  }
]