export const TrajectoryTypes = {
    trajectoryNames:['raw', 'filtered', 'sp_error_fix', 'rdp','cubicspline=0.1', 'cubicspline=0.01', 'cubicspline=0.001','aedt_input', 'aedt_output'],
    trajectoryColors: {
        raw: [0, 153, 255],
        filtered: [0, 255, 204],
        sp_error_fix: [0, 153, 51],
        rdp:[255, 255, 153],
        'cubicspline=0.1': [204, 153, 255],
        'cubicspline=0.01': [204, 102, 255],
        'cubicspline=0.001': [204, 51, 255],
        aedt_input: [255, 255, 153],
        aedt_output: [255, 204, 102]
      },
}