import * as React from 'react';
import { useState } from 'react';
import '../App.css';
import { PlotHeaders } from "../static_data/plot-headers";
import { TrajectoryTypes } from "../static_data/trajectory-types";

function returnConstructorObj() {
  let finalObj = {}
  TrajectoryTypes.trajectoryNames.map(traj => {
    finalObj[traj] = false
  })
  finalObj['raw'] = true
  return finalObj
}
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(a) {
  return "#" + componentToHex(a[0]) + componentToHex(a[1]) + componentToHex(a[2]);
}

function StyleControls(props) {

  const [trajectory, setTrajectory] = useState(returnConstructorObj());

  const [groundTrack, setgroundTrack] = useState(returnConstructorObj());

  const [color, setColor] = useState(TrajectoryTypes.trajectoryColors);

  const [airports, setAirports] = useState(true)
  const [waypoints, setWaypoints] = useState(true)


  const onTrajectoryChange = (name, value) => {
    setTrajectory({ ...trajectory, [name]: value });
    let ret = []
    // eslint-disable-next-line
    Object.keys(trajectory).map((k) => {
      if (trajectory[k]) {
        ret.push(k)
      }
    })

    if (value) { ret.push(name) }
    else {
      ret = ret.filter(function (value, index, arr) {
        return value !== name;
      });

    }

    props.chfn(ret)
  };

  const onGTChange = (name, value) => {
    setgroundTrack({ ...groundTrack, [name]: value });
    let ret = []
    // eslint-disable-next-line
    Object.keys(groundTrack).map((k) => {
      if (groundTrack[k]) {
        ret.push(k)
      }
    })

    if (value) { ret.push(name) }
    else {
      ret = ret.filter(function (value, index, arr) {
        return value !== name;
      });

    }

    props.gtfn(ret)
  };

  const onColorChange = (name, value) => {

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);
    let rgb = result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
    setColor({ ...color, [name]: rgb });
    props.colfn([name, rgb])
  };

  const onAirportChange = (val) => {
    setAirports(val);
    props.airptfn(val)
  }

  const onWaypointChange = (val) => {
    setWaypoints(val);
    props.wayptfn(val)
  }

  const onYaxisChange = (val) => {
    props.yaxisfn(val)
  }

  const onXaxisChange = (val) => {
    props.xaxisfn(val)
  }

  return (
    <div className="control-panel">
      <h3 className="flightName">Flight: {props.flight_info['num']}</h3>
      <h5 className="aircraftType">{props.flight_info['aircraft']}</h5>
      <hr />
      {TrajectoryTypes.trajectoryNames.map(name => (
        <div key={name} className="input">
          <label>{name}</label>
          <input
            className={'trajinput'}
            type="checkbox"
            checked={trajectory[name]}
            onChange={evt => onTrajectoryChange(name, evt.target.checked)}
          />
          <input
            className={'gtrackinput'}
            type="checkbox"
            checked={groundTrack[name]}
            onChange={evt => onGTChange(name, evt.target.checked)}
          />
          <input
            className={'gtrackinput'}
            type="color"
            value={rgbToHex(color[name])}

            onChange={evt => onColorChange(name, evt.target.value)}
          />

        </div>
      ))}
      <hr />
      <div className="input">
        <label>Airports</label>
        <input
          className={'trajinput'}
          type="checkbox"
          checked={airports}
          onChange={evt => onAirportChange(evt.target.checked)}
        />

      </div>
      <div className="input">
        <label>Waypoints</label>
        <input
          className={'trajinput'}
          type="checkbox"
          checked={waypoints}
          onChange={evt => onWaypointChange(evt.target.checked)}
        />
      </div>
      <div className="input">
        <label>Graph Y-axis</label>
        <select className={'trajinput'} onChange={evt => onYaxisChange(evt.target.value)}>
          {Object.keys(PlotHeaders).map(name => (
            <option>{name}</option>
          ))}
        </select>
      </div>

      <div className="input">
        <label>Graph X-axis</label>
        <select className={'trajinput'} onChange={evt => onXaxisChange(evt.target.value)}>
          {Object.keys(PlotHeaders).map(name => {
            return name === "Time" ? <option selected>{name}</option> : <option>{name}</option>
          })}
        </select>
      </div>

    </div>
  );
}

export default React.memo(StyleControls);