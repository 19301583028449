import React from "react";
// Plotly Library
import Plot from 'react-plotly.js';
// Material UI Dependencies
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PublishIcon from '@material-ui/icons/Publish';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
// Local Components
import ControlPanel from './components/control-panel';
import Player from './components/player'
import { Airports } from './static_data/airports-sfba'
import { Waypoints } from './static_data/waypoints-sfba'
import { PlotHeaders } from "./static_data/plot-headers";
import { TrajectoryTypes } from "./static_data/trajectory-types";

//Deck-GL Dependencies
import { PathLayer } from '@deck.gl/layers';
import { TripsLayer } from '@deck.gl/geo-layers';
import DeckGL, {FlyToInterpolator} from 'deck.gl';
import { IconLayer } from '@deck.gl/layers';
import ReactMapGL from "react-map-gl";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYmJzcyIsImEiOiI0VENjNkJjIn0.Qq5k4uQwjXuOnbxaQayvCw';

const INITIAL_VIEW_STATE = {
  longitude: -120.271538,
  latitude: 35.14151,
  zoom: 8,
  pitch: 0,
  maxPitch: 79,
  bearing: 0
};

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, mask: true }
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.setTrajTypes = this.setTrajTypes.bind(this)
    this.setGTTypes = this.setGTTypes.bind(this)
    this.setColTypes = this.setColTypes.bind(this)
    this.setAirports = this.setAirports.bind(this)
    this.setWaypoints = this.setWaypoints.bind(this)
    this.setYaxis = this.setYaxis.bind(this)
    this.updateAnimation = this.updateAnimation.bind(this)
    this.state = {
      trajectoryTypes: ['raw'],
      groundTrackTypes: ['raw'],
      flightIdx: 0,
      dataReceived: false,
      trajectoryColors: TrajectoryTypes.trajectoryColors,
      altitudeDrawer: false,
      hoverInfo: '',
      yaxis: 'Altitude',
      xaxis: 'Time',
      showAirports: true,
      showWaypoints: true,
      animation: {
        active: false,
        play: false,
        speed: 20,
        currentTime: 0
      },
      viewState:{
        longitude: -120.271538,
        latitude: 35.14151,
        zoom: 8,
        pitch: 0,
        maxPitch: 79,
        bearing: 0
      },
      uploadDialog: false,

    };
  }

  componentDidMount() {
    this.getData('./trajectories/demo.traj')
  }

  // Function to locally read from a traj file (JSON) and store as a state
  getData = (file) => {
    let t = this;
    let a = require(`${file}`)
    fetch(a.default,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log({
          longitude: myJson[0]['raw'][0]['lon'],
          latitude: myJson[0]['raw'][0]['lat'],
          zoom: 8,
          pitch: 0,
          bearing: 0
        })
        t.setState({
          trajFile: myJson[0],
          dataReceived: true,
          viewState:{
            longitude: myJson[0]['raw'][0]['lon'],
            latitude: myJson[0]['raw'][0]['lat'],
            zoom: 8,
            pitch: 0,
            bearing: 0,
            maxPitch: 79,
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator()
          }
        })
      });
  };

  // Function to convert datetime to epoch
  // TODO: Covert from local time to GMT
  epoch(date) {
    const dt = new Date(date)
    return Date.parse(dt) / 1000
  }

  //MODES -  deck, ground, vert, plot
  format(traj, mode) {
    let data = []
    let start_time = this.epoch(traj['start_time'])
    const trajs = mode === 'deck' || mode === 'plot' ? this.state.trajectoryTypes : this.state.groundTrackTypes
    // eslint-disable-next-line
    trajs.map(tType => {
      let inc = traj[tType]
      let new_data = {
        name: traj['flight_id'],
        type: tType
      }
      let path = []
      let timestamps = []
      // For mode 'PLOT'
      let plotObj = {}
      Object.keys(PlotHeaders).map(key=>{
        plotObj[key]={
          exists: inc[0].hasOwnProperty(PlotHeaders[key]['obj']),
          data:[]
        }
      })

      if (mode === 'vert') {
        if (this.state.trajectoryTypes.includes(tType)) {
          for (let i = 0; i < inc.length; i += 10) {
            let timestamps = []
            let pt = inc[i]
            let nd = {
              name: traj['flight_id'],
              type: tType
            }
            nd['path'] = [[pt['lon'], pt['lat'], 0], [pt['lon'], pt['lat'], pt['altitude']]]
            let timstm = this.epoch(pt['time'])
            if (timstm < start_time) {
              //IF TIMESTAMP IN 12HR FORMAT THEN CONVERT TO 24HR FORMAT
              timstm = timstm + (12 * 60 * 60)
            }
            timestamps.push(timstm - start_time)
            nd['timestamps'] = timestamps
            data.push(nd)
          }
        }
      }
      else {
        // eslint-disable-next-line
        inc.map((each) => {
          if (mode === "deck") {
            
            path.push([each['lon'], each['lat'], each['altitude']])
          }
          else if (mode === "ground") {
            path.push([each['lon'], each['lat'], 0])
          }
          let timstm = this.epoch(each['time'])
          if (timstm < start_time) {
            //IF TIMESTAMP IN 12HR FORMAT THEN CONVERT TO 24HR FORMAT
            timstm = timstm + (12 * 60 * 60)
          }
          timestamps.push(timstm - start_time)
          Object.keys(plotObj).map(key=>{
            plotObj[key]['exists'] && plotObj[key]['data'].push(each[PlotHeaders[key]['obj']])
          })
        })
        new_data['timestamps'] = timestamps
        new_data['path'] = path
      
        //For mode PLOT
      
        Object.keys(plotObj).map(key=>{
          if(plotObj[key]['exists']){
            new_data[PlotHeaders[key]['obj']] = plotObj[key]['data']
          }
        })
        data.push(new_data)
      }
    });
    return data
  }

  getMaxTime(traj) {
    let start_time = this.epoch(traj['start_time'])
    let maxTime = this.epoch(traj['arrival_time']) - start_time
    return maxTime
  }

  //----------- PASSING FUNCTIONS ----------------//
  setTrajTypes(a) {
    this.setState({ trajectoryTypes: a })
  }

  setGTTypes(a) {
    this.setState({ groundTrackTypes: a })
  }

  setColTypes(coldata) {
    this.setState({
      trajectoryColors: {
        ...this.state.trajectoryColors,
        [coldata[0]]: coldata[1]
      }
    })
  }

  setAirports(v) {
    this.setState({ showAirports: v })
  }

  setWaypoints(v) {
    this.setState({ showWaypoints: v })
  }

  setYaxis(v) {
    this.setState({ yaxis: v })
  }

  setXaxis = (v) => {
    this.setState({ xaxis: v })
  }

  updateAnimation(v) {
    this.setState({ animation: v })
  }

  //----------- SUPPORT FUNCTIONS ----------------//

  toggleDrawer = () => {
    this.setState({
      altitudeDrawer: !this.state.altitudeDrawer
    })
  }

  getListY = (data, i) => {
    return data.map(each => each[i])
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  rgbToHex(a) {
    return "#" + this.componentToHex(a[0]) + this.componentToHex(a[1]) + this.componentToHex(a[2]);
  }


  getClosest(xdata, val) {
    let max = xdata.length;
    for (let i = 0; i < xdata.length; i++) {
      if (xdata[i] > val) {
        max = i - 1;
        break;
      }
    }
    return max
  }

  // Function to return to plotly graph component
  returnPlot = () => {

    let plotData = []
    let formattedData = this.format(this.state.trajFile, 'plot');
    let maxt = this.getMaxTime(this.state.trajFile)
    let yfn, ytitle, ymax, ymin, addup, xfn = [0,0], xtitle;
    if (this.state.animation.active) {
      ymax = 0;
      ymin = 0;
    }

    plotData = formattedData.map(traj => {
      let reference_Y = PlotHeaders[this.state.yaxis]
      let reference_X = PlotHeaders[this.state.xaxis]
      if (traj.hasOwnProperty(reference_Y['obj']) && traj.hasOwnProperty(reference_X['obj'])) {
        yfn = traj[reference_Y['obj']].map(each => each)
        ytitle = reference_Y['title']
        addup = reference_Y['offset']
        let time_data = traj['timestamps'].map(each => each / 60)
        xfn = traj[reference_X['obj']].map(each => each)
        xtitle = reference_X['title']
        if (Math.max(...yfn) > ymax) {
          ymax = Math.max(...yfn) + addup
        }
        if (Math.min(...yfn) < ymin) {
          ymin = Math.min(...yfn) - addup
        }
        if (this.state.animation.active) {
          let currentTime = this.state.animation.currentTime;
          let tidx = time_data.indexOf(currentTime / 60);

          if (tidx !== -1) {
            yfn = yfn.slice(0, tidx + 1)
          }
          else {
            tidx = this.getClosest(time_data, currentTime / 60)
            yfn = yfn.slice(0, tidx + 1)
          }

        }
        return {
          x: xfn,
          y: yfn,
          type: 'scatter',
          name: traj.type,
          mode: 'lines',
          marker: { color: this.rgbToHex(this.state.trajectoryColors[traj.type]) },
        }
      }
      return {}

    })

    return <Plot
      data={plotData}
      config={{ responsive: true }}
      layout={{
        height: 240,
        xaxis: {
          title: xtitle,
          range: [xfn[0], xfn.slice(-1)[0]],
          showgrid: true,
          gridcolor: '#4d4d4d',
          // tick0: 0,
          // dtick: 1,
          tickcolor: '#fff'
        },
        yaxis: {
          title: ytitle,
          range: [ymin, ymax],
          showgrid: true,
          gridcolor: '#4d4d4d',
          tickcolor: '#fff'
        },
        margin: {
          l: 50,
          r: 30,
          b: 50,
          t: 30,
          pad: 0
        },
        font: {
          color: '#ffffff'
        },
        paper_bgcolor: '#363434',
        plot_bgcolor: '#363434',
        showlegend: true,
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1
        }
      }}
    />
  }

  // Function to set tooltip message when icon/path hovered
  setHoverInfo(info) {
    if (info.picked) {
      if (info.layer.id === "airports" || info.layer.id === "waypoints") {
        this.setState({
          hovermsg: `${info.object.name}`
        })
      }
      else {
        this.setState({
          hovermsg: `${info.object.name}: ${info.object.type}`
        })
      }
    }
    this.setState({ hoverInfo: info })
  }

  handleDialogOpen = () => {
    this.setState({
      uploadDialog: true
    })
  }

  handleDialogClose = () => {
    this.setState({
      uploadDialog: false
    })
  }

  handleUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      this.setState({
        loadedFile: JSON.parse(e.target.result)[0],
        loadedF: true,
        
      })
      //setFiles(e.target.result);
    };
  }

  loadFile = () => {
    this.setState({
      trajFile: this.state.loadedFile,
      viewState:{
        longitude: this.state.loadedFile['raw'][0]['lon'],
        latitude: this.state.loadedFile['raw'][0]['lat'],
        zoom: 8,
        pitch: 0,
        bearing: 0,
        maxPitch: 79,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator()
      },
      uploadDialog: false,
      loadedF: false,
      loadedFile: []
    })
  }

  render() {
    return (
      <div>
        {this.state.dataReceived &&
          <>
            <Drawer variant="persistent" anchor={'top'} open={this.state.altitudeDrawer} onClose={this.toggleDrawer}>
              {this.returnPlot()}
            </Drawer>
            <ControlPanel chfn={this.setTrajTypes}
              gtfn={this.setGTTypes}
              colfn={this.setColTypes}
              airptfn={this.setAirports}
              wayptfn={this.setWaypoints}
              yaxisfn={this.setYaxis}
              xaxisfn={this.setXaxis}
              flight_info={{ 'num': this.state.trajFile['flight_id'], 'aircraft': this.state.trajFile['ac_type'] }} />
            <Player
              anmnfn={this.updateAnimation} animation={this.state.animation} maxT={this.getMaxTime(this.state.trajFile)}
            />

            <Fab className={"altitudeButton"} onClick={this.toggleDrawer} color="primary" aria-label="add">
              <ShowChartIcon />
            </Fab>

            <Fab className={"uploadButton"} aria-label="upload" onClick={this.handleDialogOpen}>
              <PublishIcon />
            </Fab>
            <Dialog open={this.state.uploadDialog} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Choose a Trajectory File (.traj) to upload</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>
            
          </DialogContentText> */}
                <input type="file" accept=".traj,.json" onChange={this.handleUpload} />
              </DialogContent>
              <DialogActions>
                {this.state.loadedF &&
                  <Button onClick={this.loadFile} color="primary">
                    Load File
                  </Button>

                }

              </DialogActions>
            </Dialog>
            <DeckGL controller={true} initialViewState={this.state.viewState} >
              <ReactMapGL mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle="mapbox://styles/mapbox/dark-v8" />
              {this.state.showAirports &&
                <IconLayer
                  id='airports'
                  data={Airports}
                  iconAtlas={'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png'}
                  iconMapping={ICON_MAPPING}
                  getIcon={d => 'marker'}
                  sizeScale={9}
                  pickable={true}
                  getPosition={d => [d.lon, d.lat]}
                  getSize={d => 5}
                  getColor={[140, 140, 0]}
                  onHover={info => this.setHoverInfo(info)}
                />
              }
              {this.state.showWaypoints &&
                <IconLayer
                  id='waypoints'
                  data={Waypoints}
                  iconAtlas={'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png'}
                  iconMapping={ICON_MAPPING}
                  getIcon={d => 'marker'}
                  sizeScale={13}
                  pickable={true}
                  getPosition={d => [d.lon, d.lat]}
                  getSize={d => 3}
                  getColor={[84, 148, 1]}
                  onHover={info => this.setHoverInfo(info)}
                />
              }
              {(this.state.animation.active) ?

                <TripsLayer
                  id='trips-layer'
                  data={this.format(this.state.trajFile, 'deck')}
                  pickable={true}
                  widthScale={20}
                  rounded={true}

                  currentTime={this.state.animation.currentTime}
                  widthMinPixels={2}
                  getPath={d => d.path}
                  getTimestamps={d => d.timestamps}
                  getColor={d => this.state.trajectoryColors[d.type]}
                  getWidth={60}
                  trailLength={100}
                  onHover={info => this.setHoverInfo(info)}
                />

                :
                <>
                  <PathLayer
                    id='path-layer'
                    data={this.format(this.state.trajFile, 'deck')}
                    pickable={true}
                    widthScale={20}
                    rounded
                    billboard
                    widthMinPixels={2}
                    getPath={d => d.path}
                    getColor={d => this.state.trajectoryColors[d.type]}
                    getWidth={30}
                    onHover={info => this.setHoverInfo(info)}
                  />
                  <PathLayer
                    id='path-layer-ground-track'
                    data={this.format(this.state.trajFile, 'ground')}
                    pickable={true}
                    widthScale={20}
                    widthMinPixels={2}
                    getPath={d => d.path}
                    getColor={d => this.state.trajectoryColors[d.type]}
                    getWidth={30}
                    onHover={info => this.setHoverInfo(info)}
                  />
                  <PathLayer
                    id='path-layer-vertical-lines'
                    data={this.format(this.state.trajFile, 'vert')}
                    pickable={true}
                    widthScale={20}
                    rounded
                    billboard
                    widthMinPixels={2}
                    getPath={d => d.path}
                    getColor={d => this.state.trajectoryColors[d.type]}
                    getWidth={12}
                    onHover={info => this.setHoverInfo(info)}
                  />
                </>
              }


              {this.state.hoverInfo.object && (
                <div style={{ color: 'white', padding: 5, height: 20, background: '#0b1338', position: 'absolute', zIndex: 1, pointerEvents: 'none', left: this.state.hoverInfo.x, top: this.state.hoverInfo.y }}>
                  {this.state.hovermsg}
                </div>
              )}
            </DeckGL>
          </>
        }

      </div>
    );
  }
}

export default App;