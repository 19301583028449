import React from "react";
// Material UI Dependencies
import Fab from '@material-ui/core/Fab';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import Slider from 'react-input-slider';

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.stop = this.stop.bind(this);
        this.activate = this.activate.bind(this);
        this.sliderChange = this.sliderChange.bind(this);
        this.state = {
            animation: this.props.animation
        }
    }

    componentDidMount(){
        
    }

    play(){
        let t = this;
        t.setState({
            animation: {
                ...t.state.animation,
                play: true,
                active: true
            }
        },()=>{
            var funcNameHere = function(){
                if ((!t.state.animation.play) || (!t.state.animation.active) || t.state.currentTime >= t.props.maxT){
                    clearInterval(loop)
                }
                else{
                    t.setState({
                        animation: {
                            ...t.state.animation,
                            currentTime: t.state.animation.currentTime + 2
                        }
                    }, ()=>{
                        t.props.anmnfn(t.state.animation)
                    })
                };
            };
            let loop = setInterval(funcNameHere, 1);
            funcNameHere();

        })
    }

    pause(){
        let t = this;
        t.setState({
            animation: {
                ...t.state.animation,
                play: false
            }
        }, ()=>{
            t.props.anmnfn(t.state.animation)
        })
    }

    stop(){
        let t = this;
        t.setState({
            animation: {
                active: false,
                play: false,
                speed: 20,
                currentTime: 0
            }
        }, ()=>{
            t.props.anmnfn(t.state.animation)
        })
    }

    sliderChange(x){
        let t = this;
        t.setState({
            animation: {
                ...t.state.animation,
                currentTime: x.x
            }
        }, ()=>{
            t.props.anmnfn(t.state.animation)
        })
    }

    activate(){
        let t = this;
        t.setState({
            animation: {
                ...t.state.animation,
                active: true
            }
        }, ()=>{
            t.props.anmnfn(t.state.animation)
        })
    }
    render() {
        return (
            <div className="player">
                {this.state.animation.play ? 
                <Fab className={"playBtn"} aria-label="pause" onClick={this.pause}>
                <PauseIcon />
            </Fab>
            :
            <Fab className={"playBtn"} aria-label="play" onClick={this.play}>
                <PlayArrowIcon />
            </Fab> 
            }
            <Fab className={"stopBtn"} aria-label="stop" onClick={this.stop}>
                <StopIcon />
            </Fab>
            <Slider
                axis="x"
                x={this.state.animation.currentTime}
                xmin={0}
                xmax={this.props.maxT}
                xstep={5}
                onChange={this.sliderChange}
                onDragStart={this.activate}
                className='playerProgress'
                styles={{
                    track: {
                      width: 600
                    },
                    
                  }}
            />
                
            </div>
        )

    }

}

export default Player;